import { CellContentType } from '@/shared/components/table/models';
import { i18n } from '@/i18n';

export const SubDriverHeaders = [
  { key: 'number', label: i18n.tc('mainTable.no'), tdClass: 'min-w-50' },
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'firstName',
    label: i18n.tc('userManagement.firstNName')
  },
  {
    key: 'lastName',
    label: i18n.tc('userManagement.lastName')
  },
  {
    key: 'phone',
    label: i18n.tc('userManagement.phone')
  },
  {
    key: 'email',
    label: i18n.tc('common.email')
  },
  {
    key: 'loads',
    label: i18n.tc('mainTable.loads')
  },
  {
    key: 'totalBillAmount',
    label: i18n.tc('mainTable.totalBillAmount')
  },
  {
    key: 'tripReplay',
    label: i18n.tc('mainTable.tripReplay'),
    content: {
      type: CellContentType.Button,
      label: i18n.tc('mainTable.tripReplay')
    }
  }
];
