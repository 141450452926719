

















import Loading from '@/shared/components/Loading.vue';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { SubDriverHeaders } from '../../models/SubDriverHeaders';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { TmsTable, Loading }
})
export default class SubDriverModal extends Vue {
  @Prop() data: any;
  headersList = SubDriverHeaders;

  onAction({ data, key }: { key: string; data: any }) {
    switch (key) {
      case 'tripReplay':
        this.$emit('tripReplay', data);
        break;
    }
  }
}
