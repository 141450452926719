








































































































































import { Component, Vue } from 'vue-property-decorator';
import { MasterDriverModule } from '@/store/index';
import { DriverService } from '@/shared/services/mater-data/driver.service';
import DriverFilters from './DriverFilters/DriverFilters.vue';
import ToggleButton from '@/shared/components/form/ToggleButton.vue';
import { DriverHeaders } from './models/DriverHeaders';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import WoBaseModal from '../../Main/components/WoBaseModal.vue';
import { JobsService } from '@/shared/services/jobs/jobs.service';
import { PermissionsService } from '@/shared/services/permission/permission.service';
import DriverModal from './Modals/DriverModal/DriverModal.vue';
import SubDriverModal from './Modals/SubDriverModal/SubDriverModal.vue';
import { ToastHelper } from '@/utils/toast.util';
import { SubDriverService } from '@/shared/services/driver/subdriver.service';

@Component({
  components: {
    DriverFilters,
    DriverModal,
    ToggleButton,
    TmsTable,
    WoBaseModal,
    SubDriverModal
  }
})
export default class Driver extends Vue {
  driverId = null;
  masterDriverModule = MasterDriverModule;
  counter = 0;
  modalTitle = '';

  showModal = true;
  loading = false;
  disabled = false;
  headersList = DriverHeaders;
  woModalId = 'wo-base-modal';
  selectedWo = null;
  tabIndex = 0;

  driverRelatedOrders = null;
  subDriverData = null;

  tripReplayDriver = null;
  tripReplayCarrier = null;

  constructor() {
    super();
  }

  updateLoading(status) {
    this.loading = status;
  }

  get tableData() {
    return this.masterDriverModule.drivers;
  }

  get isLastPage(): boolean {
    return !MasterDriverModule.meta.lastKey;
  }

  created() {
    this.disabled = PermissionsService.can('MASTER', 'DRIVER', 'View');
  }

  async changeDriver(drive) {
    if (this.showModal) {
      this.loading = true;
      this.driverRelatedOrders = await JobsService.getJobsByDriverId(drive.id);
      this.$bvModal.show('driver-modal');
      this.driverId = drive.id;
      this.modalTitle = `${drive.position} driver detail`;
      this.loading = false;
    }
    this.showModal = true;
  }

  onAction({ data, key }: { key: string; data: any }) {
    switch (key) {
      case 'active':
        this.changeActive(data);
        break;
      case 'subdriver':
        this.subdriver(data);
        break;
      case 'tripReplay':
        this.tripReplay(data);
        break;
      default:
        this.changeDriver(data);
    }
  }

  tripReplay(data) {
    this.tripReplayDriver = data.id;
    this.$bvModal.show('TripReplayModal');
  }

  carrierTripReplay(data) {
    this.tripReplayCarrier = data.id;
    this.$bvModal.hide('sub-driver-modal');
    this.$bvModal.show('TripReplayModal');
  }

  async subdriver(data: any) {
    this.loading = true;
    this.tripReplayDriver = data.id;
    try {
      this.subDriverData = await SubDriverService.getSubDriverDetails(data.id);
      if (this.subDriverData && this.subDriverData.length > 0) {
        this.$bvModal.show('sub-driver-modal');
      } else {
        ToastHelper.show(
          `Driver`,
          this.$t('driver.nodriverfound').toString(),
          5000,
          'danger'
        );
      }
    } catch (err) {
      ToastHelper.show(`Driver`, err.message, 5000, 'danger');
    } finally {
      this.loading = false;
    }
  }

  onInputChange({ field, data }) {
    switch (field) {
      case 'active':
        this.changeActive(data);
        break;
      default:
        break;
    }
  }

  async changeActive(driver) {
    this.loading = true;

    this.driverRelatedOrders = await JobsService.getJobsByDriverId(driver.id);
    driver.relatedOrders = this.driverRelatedOrders;

    if (driver.relatedOrders && driver.relatedOrders.length) {
      driver.active = true;
      this.$bvModal.show('active-driver');
    } else {
      await DriverService.changeDriverActive(driver.id, {
        active: driver.active
      });
    }

    this.loading = false;
  }

  onRowClicked(row) {
    row.category = row.type;
    this.selectedWo = row;
    this.tabIndex = 0;
    this.$bvModal.show(this.woModalId);
  }
}
