import { BaseClass } from '../base/base.service';

export class SubDriverProvider extends BaseClass {
  constructor() {
    super('carrier/');
  }
  async getSubDriverDetails(id: string) {
    const resp = await this.get(`sub-drivers?driver-id=${id}`);
    return resp.data;
  }
}

export const SubDriverService = new SubDriverProvider();
