import { i18n } from '@/i18n';
import { CellContentType } from '@/shared/components/table/models';
import { DATE_API_FORMAT } from '@/utils/date.util';
import moment from 'moment-timezone';

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

export const DriverHeaders = [
  { key: 'number', label: i18n.tc('mainTable.no'), tdClass: 'min-w-50' },
  {
    key: 'id',
    label: 'ID'
  },
  {
    key: 'division',
    label: i18n.tc('reports.div')
  },
  {
    key: 'position',
    label: i18n.tc('mainTable.position')
  },
  {
    key: 'tabletId',
    label: i18n.tc('mainTable.tabletId')
  },
  {
    key: 'firstName',
    label: i18n.tc('mainTable.name')
  },
  {
    key: 'cellPhone',
    label: i18n.tc('mainTable.cell')
  },
  {
    key: 'email',
    label: i18n.tc('common.email')
  },

  {
    key: 'city',
    label: i18n.tc('scheduleTable.city')
  },
  {
    key: 'state',
    label: i18n.tc('mainTable.state')
  },
  {
    key: 'zip',
    label: i18n.tc('mainTable.zip')
  },
  {
    key: 'driverLicenseNumber',
    label: i18n.tc('mainTable.dl')
  },
  {
    key: 'driverLicenseExpirationDate',
    label: i18n.tc('mainTable.dlLicense'),
    formatter: getDay
  },
  {
    key: 'haz',
    label: i18n.tc('order.haz')
  },
  {
    key: 'twicExpirationDate',
    label: i18n.tc('mainTable.twicExpiration'),
    formatter: getDay
  },
  {
    key: 'medicalExpirationDate',
    label: i18n.tc('mainTable.medicalExpiration'),
    formatter: getDay
  },
  {
    key: 'drugTestDate',
    label: i18n.tc('mainTable.drugTest'),
    formatter: getDay
  },
  {
    key: 'mc',
    label: i18n.tc('mainTable.mc')
  },
  {
    key: 'dot',
    label: i18n.tc('mainTable.dot')
  },
  {
    key: 'hireDate',
    label: i18n.tc('mainTable.hire'),
    formatter: getDay
  },
  {
    key: 'terminateDate',
    label: i18n.tc('mainTable.terminate'),
    formatter: getDay
  },
  {
    key: 'active',
    label: i18n.tc('mainTable.active'),
    content: { type: CellContentType.Switcher }
  },
  {
    key: 'subdriver',
    label: i18n.tc('mainTable.action'),
    hideCondition: ({ position }) => position !== 'Carrier',
    content: {
      type: CellContentType.Button,
      label: i18n.tc('mainTable.subdrivers')
    }
  },
  {
    key: 'tripReplay',
    label: 'Trip replay',
    content: {
      type: CellContentType.Button,
      label: 'Trip Replay'
    }
  }
];
